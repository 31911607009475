import React from "react";
import { RichText } from "prismic-reactjs";
//import { withPreview } from "gatsby-source-prismic-graphql";
import { linkResolver, getUrlFromLink } from "@utilities/link-resolver.js";
import get from "lodash/get";
import { graphql, Link } from "gatsby";
import Layout from "@components/layout";

import "@scss/page.scss";

export const pageQuery = graphql`
  query generalPageQuery($uid: String) {
    allPrismicPage(filter: { uid: { eq: $uid }, lang: { eq: "en-us" } }) {
      edges {
        node {
          uid
          data {
            title {
              text
              raw
              html
            }
            content {
              text
              raw
              html
            }
            header_image {
              url
            }
            promo_content {
              text
              raw
              html
            }
            promo_cta
            promo_link_to {
              link_type
              type
              uid
              url
            }
          }
        }
      }
    }
  }
`;

const Page = (props) => {
  var data = props.data;
  const currentPage = get(data, "allPrismicPage.edges[0].node");

  return (
    <Layout>
      <div className="general-page" id={currentPage.uid}>
        <section>
          {currentPage.data.title && (
            <h2>{RichText.asText(currentPage.data.title.raw)}</h2>
          )}
          {currentPage.data.header_image.url && (
            <img src={currentPage.data.header_image.url} />
          )}
          {currentPage.data.promo_content.text && (
            <section className="general-page__top-promo">
              {RichText.render(
                currentPage.data.promo_content.raw,
                linkResolver
              )}
              <Link to={getUrlFromLink(currentPage.data.promo_link_to)}>
                {currentPage.data.promo_cta}
              </Link>
            </section>
          )}
          {currentPage.data.content && (
            <div className="general-page__content">
              {RichText.render(currentPage.data.content.raw, linkResolver)}
            </div>
          )}
        </section>
      </div>
    </Layout>
  );
};

export default Page;
